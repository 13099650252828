<template>
    <div></div>
</template>

<script >
export default {
   name: "SignIn", 
   created(){
       this.$root.$emit("openLogin")
   }
}
</script>
